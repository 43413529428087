<template>
  <a-drawer
    title="发起退款"
    width="700"
    :visible="visible"
    :closable="false"
    @close="onClose"
  >
    <!-- from -->
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 16 }"
    >
      <!-- 支付渠道 -->
      <a-form-item label="支付渠道">
        {{ detail.pay_channel_name }}
        <!-- {{ (PayChannels.find(item => item.value === detail.pay_channel) || {}).text || '-' }} -->
      </a-form-item>
      <!-- 支付订单号 -->
      <a-form-item label="支付订单号">
        {{ detail.trade_no }}　{{ detail.douyin_trade_no }}　{{ detail.other_trade_no }}
      </a-form-item>
      <!-- 归属项目 -->
      <a-form-item label="归属项目">
        {{ detail.project_name }}
      </a-form-item>
      <!-- 用户ID -->
      <a-form-item label="用户ID">
        {{ detail.user_id }}
      </a-form-item>
      <!-- 购买内容 -->
      <a-form-item label="购买内容">
        {{ detail.pay_content }}
      </a-form-item>
      <!-- 账户剩余 -->
      <a-form-item label="账户剩余">
        {{ detail.account_content }}
      </a-form-item>
      <!-- 支付金额 -->
      <a-form-item label="支付金额">
        {{ $pub.KEEP_NUMBER_DECIMAL(detail.order_amount / 100, 2, false, true) }}元
      </a-form-item>
      <!-- 可退金额 -->
      <a-form-item label="可退金额">
        {{ $pub.KEEP_NUMBER_DECIMAL(detail.can_refund_amount / 100, 2, false, true) }}元
      </a-form-item>
      <!-- 支付钻石 -->
      <a-form-item label="支付钻石" v-if="detail.order_diamond">
        {{ detail.order_diamond }}个
      </a-form-item>
      <!-- 可退钻石 -->
      <a-form-item label="可退钻石" v-if="detail.order_diamond">
        {{ detail.can_refund_diamond }}个
      </a-form-item>
      <!-- 退款金额 -->
      <a-form-item
        label="退款金额"
        name="amount"
        :rules="[{ required: true, validator: validatorPrice }]"
      >
        <a-input
          class="form-input"
          v-model:value="formState.amount"
          placeholder="请输入"
          suffix="元"
        />
        <a @click="touchFull" style="margin-left: 14px;">全部退款</a>
      </a-form-item>
      <!-- 退款钻石 -->
      <a-form-item
        v-if="detail.order_diamond"
        label="退款钻石"
        name="refund_diamond"
        :rules="[{ required: true, validator: validatorNum }]"
      >
        <a-input
          class="form-input"
          v-model:value="formState.refund_diamond"
          placeholder="请输入"
          suffix="个"
        />
        <a @click="touchFullDiamond" style="margin-left: 14px;">全部退款</a>
      </a-form-item>
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <div class="footer-view">
        <div style="flex: 1;"></div>
        <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
        <a-button type="primary" @click="touchSubmit">确定</a-button>
      </div>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { message } from 'ant-design-vue'
import Loading from '@/components/Loading'
import Pub from '@/utils/public'
import { refund } from '@/api/copyright'

// emit
const emit = defineEmits(['success'])
// from
const RefForm = ref(null)
// 显示
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 详情
let detail = ref({})
// 表单参数
const formState = reactive({
  // 金额
  amount: undefined,
  // 钻石
  refund_diamond: undefined
})

// 显示
function showDrawer (data) {
  // 获取详情
  detail.value = data
  // 显示
  visible.value = true
}

// 关闭
function onClose () {
  if (!isLoading.value) {
    visible.value = false
    resetFields()
    formState.amount = undefined
    formState.refund_diamond = undefined
  }
}

// 重置
function resetFields () {
  RefForm.value.resetFields()
}

// 提交
function touchSubmit () {
  RefForm.value.validate().then(() => {
    isLoading.value = true
    let params = {
      trade_no: detail.value.trade_no,
      refund_amount: formState.amount * 1000 / 10,
      refund_diamond: formState.refund_diamond * 1
    }
    refund(params).then(res => {
      isLoading.value = false
      const { code, data } = res
      if (code === 0) {
        message.success('操作成功')
        onClose()
        emit('success')
      } else {
        message.error(res.message)
      }
    })
  }).catch((err) => {
    console.log(err)
  })
}

// 效验 - 价格
function validatorPrice (rule, value) {
  if (!value) {
    return Promise.reject('请输入${label}')
  } else if (!Pub.REG_IS_INTEGER_OR_FLOAT(value)) {
    return Promise.reject('必须为正数，且最多保留两位小数')
  }
  return Promise.resolve()
}
function validatorNum (rule, value) {
  if (!value) {
    return Promise.reject('请输入${label}')
  } else if (!Pub.REG_IS_INTEGER(value)) {
    return Promise.reject('必须为正数，不能有小数点')
  }
  return Promise.resolve()
}

// 全部退款
function touchFull () {
  formState.amount = Pub.KEEP_NUMBER_DECIMAL(detail.value.can_refund_amount / 100, 2, false, true)
}

function touchFullDiamond () {
  formState.refund_diamond = detail.value.order_diamond
}

// 抛出
defineExpose({
  showDrawer
})

</script>

<style scoped>
.form-input {
  width: 50%;
}
.form-item-clear {
  margin-bottom: 0;
}
.footer-view {
  display: flex;
  align-items: center;
}
.footer-hint {
  color: #5A5A5A;
}
</style>