<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>运营管理</a-breadcrumb-item>
    <a-breadcrumb-item>用户退款</a-breadcrumb-item>
  </a-breadcrumb>
  <!-- 头部功能条 -->
  <div class="head-tool-view">
    <div style="position: relative;">
      <!-- UID -->
      <span class="tool-title">支付渠道：</span>
      <a-select
        v-model:value="cpay_channel"
        placeholder="请选择"
        style="width: 200px;"
      >
        <a-select-option
          v-for="item in RefundPayChannels"
          :key="item.value"
          :value="item.value"
        >
          {{ item.text }}
        </a-select-option>
      </a-select>
      <!-- 金额 -->
      <span class="tool-title">支付订单号：</span>
      <a-input v-model:value="cnumber" style="width: 200px;" placeholder="请输入" />
      <!-- 操作 -->
      <a-button type="primary" style="margin-left: 16px;" @click="touchRefund">发起退款</a-button>
      <!-- 加载组件 -->
      <Loading :loading="cisLoading"></Loading>
    </div>
    <!-- 分割线 -->
    <div class="head-tool-space"></div>
  </div>
  <!-- 功能条 -->
  <div class="tool-view">
    <!-- 日期： -->
    <span class="tool-title">日期：</span>
    <a-range-picker
      v-model:value="dateRange"
      :format="dateFormat"
    />
    <!-- UID： -->
    <span class="tool-title">UID：</span>
    <a-input v-model:value="uid" style="width: 160px;" placeholder="请输入" />
    <!-- 推广ID： -->
    <span class="tool-title">支付订单号：</span>
    <a-input v-model:value="number" style="width: 160px;" placeholder="请输入" />
    <!-- 操作 -->
    <a-button type="primary" style="margin-left: 16px;" @click="handleSearch">查询</a-button>
    <a-button style="margin-left: 16px;" @click="handleReset">重置</a-button>
    <div style="flex: 1;"></div>
  </div>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :pagination="pagination"
    :loading="isLoading"
    @change="handleTableChange"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record }">
      <!-- 支付渠道 -->
      <template v-if="column.key === 'pay_channel'">
        {{ (PayChannels.find(item => item.value === record[column.key]) || {}).text || '-' }}
      </template>
      <!-- 状态 -->
      <template v-if="column.key === 'status'">
        <span>{{ (RefundStatus.find(item => item.value === record[column.key]) || {}).text || '-' }}</span>
        <!-- <a-tooltip>
          <template #title>prompt text</template>
          <info-circle-outlined style="margin-left: 5px;" />
        </a-tooltip> -->
      </template>
      <!-- 金额 -->
      <template v-if="column.key === 'refund_amount' || column.key === 'order_amount'">
        {{ $pub.KEEP_NUMBER_DECIMAL((record[column.key] || 0) / 100, 2, false, true) }}
      </template>
      <!-- 操作 -->
      <template v-if="column.key === 'operation'">
        <a class="operation-item" @click="touchInfo(record)">查看</a>
      </template>
    </template>
  </a-table>
  <!-- 发起退款 -->
  <RefundDrawer ref="RefRefundDrawer" @success="getList"></RefundDrawer>
  <!-- 其他信息 -->
  <RefundInfoDrawer ref="RefRefundInfoDrawer"></RefundInfoDrawer>
</template>

<script setup>
import { onBeforeMount, ref, reactive, createVNode } from 'vue'
import { message, Modal } from 'ant-design-vue'
import Pub from '@/utils/public'
import dayjs from 'dayjs'
import { PayChannels, RefundStatus, RefundPayChannels } from '@/utils/constantList'
import { refundOrder, refundList } from '@/api/copyright'
import Loading from '@/components/Loading'
import RefundDrawer from './components-refund/RefundDrawer'
import RefundInfoDrawer from './components-refund/RefundInfoDrawer'

// 发起退款
let RefRefundDrawer = ref(null)
// 其他信息
let RefRefundInfoDrawer = ref(null)
// 加载
let isLoading = ref(false)
// 日期范围
let dateRange = ref([dayjs().subtract(30, 'day'), dayjs()])
// 日期format
const dateFormat = 'YYYY-MM-DD'
// 头部 - 加载
let cisLoading = ref(false)
// 头部 - 支付渠道
let cpay_channel = ref(1)
// 头部 - 支付订单号
let cnumber = ref(undefined)
// UID
let uid = ref(undefined)
// 支付订单号
let number = ref(undefined)
// 数据源
let dataSource = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源（付费转化率、权重本期不做）
let columns = ref([
  {
    title: '退款时间',
    dataIndex: 'created_at',
    key: 'created_at'
  },
  {
    title: '退款金额(元)',
    dataIndex: 'refund_amount',
    key: 'refund_amount'
  },
  {
    title: 'UID',
    dataIndex: 'user_id',
    key: 'user_id'
  },
  {
    title: '订单金额(元)',
    dataIndex: 'order_amount',
    key: 'order_amount'
  },
  {
    title: '支付渠道',
    dataIndex: 'pay_channel',
    key: 'pay_channel'
  },
  {
    title: '归属项目',
    dataIndex: 'project_name',
    key: 'project_name'
  },
  {
    title: '退款状态',
    dataIndex: 'status',
    key: 'status'
  },
  {
    title: '其他信息',
    dataIndex: 'operation',
    key: 'operation'
  }
])
// 钩子函数
onBeforeMount (() => {
  // 获取注册用户列表
  getList()
})

// 新建回传
function touchRefund () {
  if (!cnumber.value) {
    message.error('支付订单号不能为空')
    return
  }
  cisLoading.value = true
  const params = {
    pay_channel: cpay_channel.value,
    trade_no: cnumber.value
  }
  refundOrder(params).then(res => {
    cisLoading.value = false
    const { code, data } = res
    if (code === 0) {
      RefRefundDrawer.value.showDrawer(data)
    } else {
      Modal.error({
        title: '创建失败',
        content: res.message
      })
    }
  }).catch(() => {
    cisLoading.value = false
    message.error('读取失败')
  })
}

// 获取列表
function getList () {
  isLoading.value = true
  const params = {
    start_time: dateRange.value ? `${dateRange.value[0].format(dateFormat)}` : undefined,
    end_time: dateRange.value ? `${dateRange.value[1].format(dateFormat)}` : undefined,
    trade_no: number.value,
    user_id: uid.value,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  refundList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      dataSource.value = data.data
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  })
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getList()
}

// 查询
function handleSearch () {
  pagination.current = 1
  // 获取列表
  getList()
}

// 重置
function handleReset () {
  // 筛选项重置
  dateRange.value = [dayjs().subtract(30, 'day'), dayjs()]
  uid.value = undefined
  number.value = undefined
  // 分页重置
  pagination.current = 1
  getList()
}

// 查看
function touchInfo (record) {
  RefRefundInfoDrawer.value.showDrawer(record)
}
</script>

<style scoped>
.head-tool-view {
  padding-top: 30px;
}
.head-tool-space {
  margin-top: 30px;
  height: 30px;
  background-color: #F0F2F5;
  margin-left: -24px;
  margin-right: -24px;
}
.tool-view {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.table-view {
  margin-top: 30px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}
.operation-item {
  margin-right: 8px;
}
</style>